import axios, { CancelToken } from "axios";
import { IUserToUpdate } from "../components/Users/UsersTab";
import { OperationCanceledError } from "../errors/OperationCanceledError";
import { ApiError } from "../errors/ApiError";
import { CommunicationError } from "../errors/CommunicationError";
import { TokenStorage } from "../TokenStorage";
import { isAuthenticationError, isRefreshTokenError, isTokenExpiredError, isUserNotFoundError, logout, objectWithoutKey, shouldRefreshToken } from "../utility";
import IApiCreditCard from "../models/Invoices/IApiCreditCard";
import ICreditCardPayment from "../models/Invoices/ICreditCardPayment";
import IIntegrationSetup from "../models/Integrations/IIntegrationSetup";
import { IPastPlanPurgePayload } from "../models/Integrations/IPastPlanPurge";
import { IAccountFilters } from "../models/IAccountFilters";
import { getEssBundleSkusFilters, getM365Filters, getSeProdSkusFilters } from "../Utilities/accountsHelper";
import { UserNotFoundError } from "../errors/UserNotFoundError";
export const API_ULR_BCC_TOKEN = "oauth2/token";
export const API_URL_CHECKUSER = "v3/echo/checkuser?version=2";
export const API_URL_GENERATE_TOKEN = "v3/auth/token";
export const API_URL_REFRESH_TOKEN = "v3/auth/refresh";
export const API_URL_GET_USER = "v3/authdb/user";

const clientAPI = {
  async checkUser(apiUrl: string) {
    return axios
      .get(`${apiUrl}/${API_URL_CHECKUSER}`, {
        method: "GET",
        data: { shouldNotRefreshToken: true },
      })
      .then(res => res);
  },

  async getApiCredentialApplications(apiUrl: string, closestParentId: string) {
    return axios
      .get(`${apiUrl}/v3/echo/oauth2/clients`, {
        method: "GET",
      })
      .then(res => res);
  },

  async addApiCredentialApplication(apiUrl: string, closestParentId: string, newApplication: any) {
    return axios
      .post(`${apiUrl}/v3/echo/oauth2/clients`, JSON.stringify(newApplication), {
        method: "POST",
      })
      .then(res => res.data);
  },

  async deleteApiCredentialApplication(apiUrl: string, closestParentId: string, applicationId: any) {
    return axios
      .delete(`${apiUrl}/v3/echo/oauth2/clients/${applicationId}`, {
        method: "DELETE",
      })
      .then(res => res.data);
  },

  async renewApiCredentialApplication(apiUrl: string, closestParentId: string, applicationId: any) {
    return axios
      .post(`${apiUrl}/v3/echo/oauth2/clients/${applicationId}`, {
        method: "POST",
      })
      .then(res => res.data);
  },

  async generateCustomToken(apiUrl: string, idToken: string) {
    return axios
      .post(`${apiUrl}/${API_URL_GENERATE_TOKEN}`, JSON.stringify({ idToken: idToken }), {
        method: "POST",
      })
      .then(res => res.data);
  },

  async preFetch(apiUrl: string) {
    return axios.get(`${apiUrl}/v3/echo/prefetch`, {
      method: "GET",
      data: { shouldNotRefreshToken: true },
    });
  },

  async fetchLoggedInPartner(apiUrl: string) {
    return axios
      .get(`${apiUrl}/v3/authdb/partner`, {
        method: "GET",
      })
      .then(res => res.data);
  },

  async fetchAccountChildren(apiUrl: string, id: number, cancelToken: CancelToken | undefined) {
    return axios
      .get(`${apiUrl}/v3/authdb/accounts/${id}/children`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async fetchAccountAllChildren(apiUrl: string, id: number, cancelToken: CancelToken | undefined) {
    return axios
      .get(`${apiUrl}/v3/authdb/accounts/${id}/children?depth=2`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async fetchPartnerChildren(apiUrl: string) {
    return axios
      .get(`${apiUrl}/v3/authdb/partner/children`, {
        method: "GET",
      })
      .then(res => res.data);
  },

  async fetchParentAccount(apiUrl: string, id: number, cancelToken?: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/authdb/accounts/${id}/parent`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async fetchAccountAddress(apiUrl: string, id: number, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${id}/addresses`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async fetchCountries(apiUrl: string, cancelToken?: CancelToken) {
    return axios
      .get(`${apiUrl}/v2/general/address/countries`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async fetchStates(apiUrl: string, countryCode: string, cancelToken?: CancelToken) {
    return axios
      .get(`${apiUrl}/v2/general/address/countries/${countryCode}/states`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async fetchAccount(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/authdb/accounts/${accountId}`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async addAccount(apiUrl: string, closestParentId: number, account: any) {
    return axios
      .post(`${apiUrl}/v3/echo/accounts/${closestParentId}`, JSON.stringify(account), {
        method: "POST",
      })
      .then(res => res.data);
  },

  async editAccount(apiUrl: string, account: any) {
    return axios
      .patch(`${apiUrl}/v3/authdb/accounts/${account.id}`, JSON.stringify({ name: account.name }), {
        method: "PUT",
      })
      .then(res => res.data);
  },

  async editAddress(apiUrl: string, account: any) {
    return axios
      .put(`${apiUrl}/v3/echo/accounts/${account.id}/addresses`, JSON.stringify(account.addresses), {
        method: "PUT",
      })
      .then(res => res.data);
  },

  async editAccountNotes(apiUrl: string, account: any, notes: string) {
    return axios
      .patch(`${apiUrl}/v3/echo/accounts/${account.id}/info`, JSON.stringify({ notes: notes }), {
        method: "PUT",
      })
      .then(res => res.data);
  },

  async deleteAccount(apiUrl: string, accountId: number) {
    return axios
      .delete(`${apiUrl}/v3/authdb/accounts/${accountId}`, {
        method: "DELETE",
      })
      .then(res => res.data);
  },

  async fetchAccountUsers(apiUrl: string, id: number, cancelToken?: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/authdb/accounts/${id}/users`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async addUser(apiUrl: string, accountId: number, user: any) {
    return axios
      .post(`${apiUrl}/v3/echo/accounts/${accountId}/users`, JSON.stringify(user), {
        method: "POST",
      })
      .then(res => res.data);
  },

  async addNewPartnerUser(apiUrl: string, accountId: number, user: any) {
    return axios
      .post(`${apiUrl}/v3/echo/accounts/${accountId}/users/newPartnerUser`, JSON.stringify(user), {
        method: "POST",
      })
      .then(res => res.data);
  },

  async editUser(apiUrl: string, userId: number, updatedUser: IUserToUpdate) {
    return axios
      .patch(`${apiUrl}/v3/echo/users/${userId}`, JSON.stringify({ name: updatedUser.name, email: updatedUser.email }), {
        method: "PATCH",
      })
      .then(res => res.data);
  },

  async editUserRole(apiUrl: string, userId: number, accountId: number, role: string, entitlements: number[]) {
    return axios
      .put(`${apiUrl}/v3/echo/users/${userId}/accounts/${accountId}/roles`, JSON.stringify({ role: role, entitlements: entitlements }), {
        method: "PUT",
      })
      .then(res => res.data);
  },

  async deleteUser(apiUrl: string, userId: number) {
    return axios
      .delete(`${apiUrl}/v3/authdb/users/${userId}`, {
        method: "DELETE",
      })
      .then(res => res.data);
  },

  async fetchUserAccount(apiUrl: string, userId: number, accountId: number, shouldNotRefreshToken: boolean, cancelToken?: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/authdb/users/${userId}/accounts/${accountId}`, {
        method: "GET",
        cancelToken: cancelToken,
        data: { shouldNotRefreshToken: shouldNotRefreshToken },
      })
      .then(res => res.data);
  },

  async fetchUserAccounts(apiUrl: string, userId: number, shouldNotRefreshToken: boolean, cancelToken?: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/authdb/users/${userId}/accounts`, {
        method: "GET",
        cancelToken: cancelToken,
        data: { shouldNotRefreshToken: shouldNotRefreshToken },
      })
      .then(res => res.data);
  },

  async fetchLoggedInUser(apiUrl: string) {
    return axios
      .get(`${apiUrl}/${API_URL_GET_USER}`, {
        method: "GET",
      })
      .then(res => res.data);
  },

  async fetchUserEntitlements(apiUrl: string, userId: number) {
    return axios
      .get(`${apiUrl}/v3/authdb/users/${userId}/entitlements`, {
        method: "GET",
      })
      .then(res => res.data);
  },

  async fetchUserEntitlementsForAccount(apiUrl: string, userId: number, accountId: number, cancelToken?: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/authdb/users/${userId}/accounts/${accountId}/entitlements`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async fetchSubpartnersWithOrders(apiUrl: string, cancelToken: CancelToken | undefined) {
    return axios
      .get(`${apiUrl}/v3/echo/subPartnersWithOLIs`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async fetchOrders(apiUrl: string, accountId: number, cancelToken: CancelToken | undefined, shouldNotRefreshToken: boolean) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/orderlineitems`, {
        method: "GET",
        cancelToken: cancelToken,
        data: { shouldNotRefreshToken: shouldNotRefreshToken },
      })
      .then(res => res.data);
  },

  async fetchOrderSerials(apiUrl: string, accountId: number, orderId: string, cancelToken: CancelToken | undefined) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/orderlineitems/${orderId}/serials`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async activateProduct(apiUrl: string, accountId: number, orderId: string, closestParentId: number) {
    return axios
      .post(`${apiUrl}/v3/echo/accounts/${closestParentId}/orderlineitems/${orderId}/serials/assign`, JSON.stringify({ accountId: accountId }), {
        method: "POST",
      })
      .then(res => res);
  },

  async deactivateProduct(apiUrl: string, accountId: number, orderId: string) {
    return axios
      .delete(`${apiUrl}/v3/echo/accounts/${accountId}/orderlineitems/${orderId}/serials/unassign`, {
        method: "DELETE",
      })
      .then(res => res.data);
  },

  async cancelSerial(apiUrl: string, accountId: number, orderId: string, serialId: string) {
    return axios
      .post(`${apiUrl}/v3/echo/accounts/${accountId}/orderlineitems/${orderId}/serials/${serialId}/cancel`, {
        method: "POST",
      })
      .then(res => res.data);
  },

  async retryActivationProduct(apiUrl: string, accountId: number, orderId: string, bccProductId: number) {
    return axios
      .post(`${apiUrl}/v3/echo/accounts/${accountId}/orderlineitems/${orderId}/serials/retry`, JSON.stringify({ bccProductId: bccProductId }), {
        method: "POST",
      })
      .then(res => res);
  },

  async assignProduct(apiUrl: string, accountId: number, orderId: string, closestParentId: number) {
    return axios
      .post(`${apiUrl}/v3/echo/accounts/${closestParentId}/orderlineitems/${orderId}/assign`, JSON.stringify({ account_id: accountId }), {
        method: "POST",
      })
      .then(res => res.data);
  },

  async unassignProduct(apiUrl: string, accountId: number, orderId: string) {
    return axios
      .delete(`${apiUrl}/v3/echo/accounts/${accountId}/orderlineitems/${orderId}`, {
        method: "DELETE",
      })
      .then(res => res.data);
  },

  async fetchBundleServicesSkus(apiUrl: string, bundleSku: string, cancelToken: CancelToken | undefined) {
    return axios
      .get(`${apiUrl}/v3/echo/bundle/${bundleSku}/services`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async changeSerial(apiUrl: string, accountId: number, orderlineItemId: string, serial: string, lineItemId: string) {
    return axios
      .post(`${apiUrl}/v3/echo/accounts/${accountId}/orderlineitems/${orderlineItemId}/serials/${serial}/change`, JSON.stringify({ lineItemId: lineItemId }), {
        method: "POST",
      })
      .then(res => res.data);
  },

  async fetchChangeSerialOptions(apiUrl: string, accountId: number, orderlineItemId: string, serial: string, cancelToken: CancelToken | undefined) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/orderlineitems/${orderlineItemId}/serials/${serial}/optionsofchange`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async fetchUsageAudit(apiUrl: string, accountId: number, filterType: string, beginWith: string, nextPageToken: string, cancelToken: CancelToken | undefined) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/exclusions/usageaudit`, {
        method: "GET",
        params: { filterType: filterType, beginWith: beginWith, nextPageToken: nextPageToken },
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async createBilledUser(apiUrl: string, accountId: number, newAuditUsers: any) {
    return axios
      .post(`${apiUrl}/v3/echo/accounts/${accountId}/exclusions`, JSON.stringify(newAuditUsers), {
        method: "POST",
      })
      .then(res => res.data);
  },

  async deleteBilledUser(apiUrl: string, accountId: number, user: string) {
    return axios
      .delete(`${apiUrl}/v3/echo/accounts/${accountId}/exclusions/${user}`, {
        method: "DELETE",
      })
      .then(res => res.data);
  },

  async fetchPaymentMethod(apiUrl: string, accountId: number) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/paymentmethod`, {
        method: "GET",
      })
      .then(res => res.data);
  },

  async editPaymentMethod(apiUrl: string, accountId: number, paymentMethod: number) {
    return axios
      .put(`${apiUrl}/v3/echo/accounts/${accountId}/paymentmethod`, JSON.stringify({ paymentMethod: paymentMethod }), {
        method: "PUT",
      })
      .then(res => res.data);
  },

  async fetchInvoices(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/invoices`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async fetchAccountsWithExclusions(apiUrl: string, accountId: number, invoiceDate: string, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/exclusions`, {
        method: "GET",
        params: { invoiceDate: invoiceDate },
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async fetchAccountExclusionsUserList(apiUrl: string, accountId: number, invoiceDate: string, skip: number, take: number, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/exclusions/userlist`, {
        method: "GET",
        params: { invoiceDate: invoiceDate, skip: skip, take: take },
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async sendEmailInvoice(apiUrl: string, accountId: number, invoiceNumber: string) {
    return axios
      .post(`${apiUrl}/v3/echo/accounts/${accountId}/invoices/${invoiceNumber}/email`, {
        method: "POST",
      })
      .then(res => res.data);
  },

  async addCreditCard(apiUrl: string, accountId: number, creditcard: IApiCreditCard) {
    return axios
      .post(`${apiUrl}/v3/echo/accounts/${accountId}/creditcards`, JSON.stringify({ ...creditcard }), {
        method: "POST",
      })
      .then(res => res.data);
  },

  async fetchCreditCards(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/creditcards`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async setDefaultCard(apiUrl: string, accountId: number, token: string) {
    return axios
      .put(`${apiUrl}/v3/echo/accounts/${accountId}/creditcards/${token}/default`, JSON.stringify({ isDefault: true, token: token }), {
        method: "PUT",
      })
      .then(res => res.data);
  },

  async setRecurringCard(apiUrl: string, accountId: number, token: string) {
    return axios
      .put(`${apiUrl}/v3/echo/accounts/${accountId}/creditcards/${token}/recurring`, JSON.stringify({ isRecurring: true, token: token }), {
        method: "PUT",
      })
      .then(res => res.data);
  },

  async deleteCreditCard(apiUrl: string, accountId: number, token: string) {
    return axios
      .delete(`${apiUrl}/v3/echo/accounts/${accountId}/creditcards/${token}`, {
        method: "DELETE",
      })
      .then(res => res.data);
  },

  async payInvoice(apiUrl: string, accountId: number, invoiceNumber: string, creditcardPayment: ICreditCardPayment) {
    let payload: any = {};
    if (creditcardPayment.creditCardId) {
      payload = { creditCardId: creditcardPayment.creditCardId };
    } else {
      payload = { creditCard: { ...creditcardPayment.creditCard, cvv: Number(creditcardPayment.creditCard?.cvv) } };
    }
    return axios
      .post(`${apiUrl}/v3/echo/accounts/${accountId}/invoices/${invoiceNumber}/payment`, JSON.stringify(payload), {
        method: "POST",
      })
      .then(res => res.data);
  },

  async fetchM365Auth(apiUrl: string, closestParentId: number, accountId: number) {
    return axios.get(`${apiUrl}/v3/spuds/authorizeaccounts/partners/${closestParentId}?accountId=${accountId}`, {}).then(res => res.data);
  },

  async addM365Auth(apiUrl: string, closestParentId: number, accountId: string, redirectUrl: string) {
    let payload: any = {};
    payload = {
      accountId: accountId,
      redirectUrl: redirectUrl,
    };
    return axios
      .post(`${apiUrl}/v3/spuds/authorizeaccounts/partners/${closestParentId}`, JSON.stringify(payload), {
        method: "POST",
      })
      .then(res => res.data);
  },

  async revokeM365Auth(apiUrl: string, closestParentId: number, accountId: number) {
    return axios
      .delete(`${apiUrl}/v3/spuds/authorizeaccounts/partners/${closestParentId}/accounts/${accountId}`, {
        method: "DELETE",
      })
      .then(res => res.data);
  },

  async fetchLastUsageSyncTime(apiUrl: string) {
    return axios
      .get(`${apiUrl}/v3/spuds/metadata/properties?serviceName=SpudsDatasync&propertyName=LastUsageSyncTime`, {
        method: "GET",
      })
      .then(res => res.data);
  },

  async loadIntegrations(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/integrations`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async loadConnectWiseIntegrationInfo(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/integrations/connectwise/info?extended=1`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async deleteIntegration(apiUrl: string, accountId: number) {
    return axios
      .delete(`${apiUrl}/v3/echo/accounts/${accountId}/integrations/connectwise`, {
        method: "DELETE",
      })
      .then(res => res.data);
  },

  async loadCWIntegrationAccounts(apiUrl: string, accountId: number, skip: number, take: number, orderBy: string | undefined, ascending: boolean | undefined, unlinkedOnly: boolean, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/integrations/connectwise/accounts`, {
        method: "GET",
        params: { skip: skip, take: take, orderBy: orderBy, ascending: ascending, unlinkedOnly: unlinkedOnly },
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async loadCWIntegrationFilteredAccounts(apiUrl: string, accountId: number, forAccount: string | undefined, forCompany: string | undefined, unlinkedOnly: boolean, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/integrations/connectwise/accounts/lookup`, {
        method: "GET",
        params: { forAccount: forAccount, forCompany: forCompany, unlinkedOnly: unlinkedOnly },
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async loadCWIntegrationLogs(apiUrl: string, accountId: number, cancelToken: CancelToken, take: number) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/integrations/connectwise/logs`, {
        method: "GET",
        params: { take: take },
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async addIntegration(apiUrl: string, accountId: number, integrationSetup: IIntegrationSetup) {
    return axios
      .post(`${apiUrl}/v3/echo/accounts/${accountId}/integrations/connectwise`, JSON.stringify({ ...integrationSetup }), {
        method: "POST",
      })
      .then(res => res.data);
  },

  async editIntegration(apiUrl: string, accountId: number, integrationSetup: IIntegrationSetup) {
    let newSetupIntegrationObj = objectWithoutKey(integrationSetup, "integrationType");
    return axios
      .put(`${apiUrl}/v3/echo/accounts/${accountId}/integrations/connectwise`, JSON.stringify({ ...newSetupIntegrationObj }), {
        method: "PUT",
      })
      .then(res => res.data);
  },

  async loadIntegrationBillingMaps(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/integrations/connectwise/billingmap`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async loadIntegrationBillingMapsOptions(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/integrations/connectwise/billingmapoptions`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async resetPastPlans(apiUrl: string, accountId: number, pastPlanPurge: IPastPlanPurgePayload) {
    return axios
      .post(`${apiUrl}/v3/echo/accounts/${accountId}/integrations/connectwise/billingmap/refreshpastplans`, JSON.stringify({ ...pastPlanPurge }), {
        method: "POST",
      })
      .then(res => res.data);
  },

  async setIntegrationBillingMaps(apiUrl: string, accountId: number, billingMaps: any) {
    return axios
      .post(`${apiUrl}/v3/echo/accounts/${accountId}/integrations/connectwise/billingmap`, JSON.stringify({ ...billingMaps }), {
        method: "POST",
      })
      .then(res => res.data);
  },

  async loadIntegrationAccountsCompanies(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/integrations/connectwise/companies`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },
  async loadIntegrationAccountsCountCompanies(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/integrations/connectwise/companies/count`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async loadIntegrationAccountsSearchCompanies(apiUrl: string, accountId: number, cancelToken: CancelToken, keyword: string) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/integrations/connectwise/companies/search`, {
        params: {
          keyword: keyword,
        },
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },
  async linkAccount(apiUrl: string, accountId: number, companyId: number) {
    return axios
      .post(`${apiUrl}/v3/echo/accounts/${accountId}/integrations/connectwise/company/${companyId}/link`, {
        method: "POST",
      })
      .then(res => res.data);
  },

  async unlinkAccount(apiUrl: string, accountId: number) {
    return axios
      .post(`${apiUrl}/v3/echo/accounts/${accountId}/integrations/connectwise/company/unlink`, {
        method: "POST",
      })
      .then(res => res.data);
  },

  async fetchCurrency(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/currency`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async exportCsv(apiUrl: string, payloadAccountIds: string) {
    return axios
      .post(`${apiUrl}/v3/echo/reportRequest`, JSON.stringify({ accountIds: payloadAccountIds }), {
        method: "POST",
      })
      .then(res => res.data);
  },

  async fetchM365DomainList(apiUrl: string, closestParentId: number, accountId: number, skip: number, take: number) {
    return axios.get(`${apiUrl}/v3/spuds/authorizeaccounts/partners/${closestParentId}/domains?accountId=${accountId}&skip=${skip}&take=${take}`, {}).then(res => res.data);
  },

  async fetchProductLicenses(apiUrl: string, accountId: number, user: string) {
    return axios.get(`${apiUrl}/v3/echo/accounts/${accountId}/products/${user}`, {}).then(res => res.data);
  },

  async filterAccounts(apiUrl: string, accountId: number, filters: IAccountFilters | undefined, directOnly?: boolean, cancelToken?: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/filters`, {
        method: "GET",
        params: { essBundleSKU: getEssBundleSkusFilters(filters), seProdSKU: getSeProdSkusFilters(filters), csProdSKU: filters?.csProdSkus, bbsProdSKU: filters?.bbsProdSkus, smbLogins: filters?.hasSmbLogins, activationErrors: filters?.hasErrors, m365Linked: getM365Filters(filters), noProductsActivated: filters?.noProducts, childAccountEmail: filters?.associatedLogin, hasIBU: filters?.hasIbu, directOnly: directOnly },
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async fetchAccountInfo(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/info`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async fetchAccountEchoUserName(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/echoUsername`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },

  async fetchAllSerials(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return axios
      .get(`${apiUrl}/v3/echo/accounts/${accountId}/serials/all`, {
        method: "GET",
        cancelToken: cancelToken,
      })
      .then(res => res.data);
  },
};

axios.interceptors.request.use(
  async config => {
    const token = TokenStorage.getAccessTokenForApiCall(config.url);
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Content-Type"] = "application/json; charset=utf-8";
    return config;
  },
  error => {
    Promise.reject(error);
  },
);
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (axios.isCancel(error)) {
      throw new OperationCanceledError();
    } else if (isAuthenticationError(error)) {
      if (isRefreshTokenError(error)) {
        logout();
      } else if (isTokenExpiredError(error)) {
        if (shouldRefreshToken(error)) {
          return refreshTokenAndRetry(error);
        }
      } else {
        handleError(error);
      }
    } else handleError(error);
    return Promise.reject(error);
  },
);

export default clientAPI;

function handleError(error: any) {
  if (error.response) {
    if (isUserNotFoundError(error)) {
      throw new UserNotFoundError(error);
    } else {
      throw new ApiError(error.response);
    }
  } else if (error.request) {
    throw new CommunicationError(error);
  }
}

function refreshTokenAndRetry(error: any): any {
  return TokenStorage.refreshToken(error.config.url)
    .then((token: any) => {
      const config = error.config;
      config.headers["Authorization"] = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .request(config)
          .then(response => {
            resolve(response);
          })
          .catch(callbackError => {
            reject(callbackError);
          });
      });
    })
    .catch((refreshTokenError: any) => {
      Promise.reject(refreshTokenError);
    });
}
