/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Typography, CircularProgress } from "@cuda-networks/bds-core";
import React, { useCallback, useEffect, useState } from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import IFilterCheckbox from "../../../models/IFilterCheckbox";
import { IAppState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import FilterAccountsOptions from "../../../models/FilterAccountsOptions";
import { computeNewActiveProductsCheckboxesFromState, computeNewStatusesCheckboxesFromState, computeStateFiltersFromCheckboxes, initializeFilterResults, setActiveProductsFilter, setStatusesFilter } from "../../../businessLogic/components/Accounts/FilterAccounts/FilterAccountListDialog";
import { applyfilterAccountsResultsAction, goToAccountIdAction, resetFiltersAction } from "../../../actions/accountActions";
import { EmailValidationRule } from "../../../fieldsValidationRules";
import AccountsTableBase from "../AccountsTableBase";
import IAccount from "../../../models/IAccount";
import { filterAccountsFromFiltersPopupAction } from "../../../actions/filterAccountsActions";
import { Backdrop } from "@material-ui/core";
import { IAccountFilters } from "../../../models/IAccountFilters";
import { useNavigate } from "react-router-dom";
import { areFiltersActive } from "../../../Utilities/accountsHelper";
import { URLFilterBuilder } from "./UrlFilterBuilder";
import useWhyDidYouUpdate from "./useWhyDidYouUpdate";
import FilterAccountsCheckboxes from "./FilterAccountsCheckboxes";
import { PAGE_SIZES } from "../../../models/TableConfig";

interface IFilterAccountListDialogProps {
  existingFilters: IAccountFilters;
  actionInProgress: boolean;
  onClose: () => void;
  newFilterByName: string;
  setInitialFocus: () => void;
  onCancel: () => void;
  onApply: () => void;
  onSearchClear: () => void;
  setActionInProgress: (inProgress: boolean) => void;
}

const FilterAccountPopover: React.FC<IFilterAccountListDialogProps> = props => {
  const { existingFilters, actionInProgress, onClose, newFilterByName, setInitialFocus, onCancel, onSearchClear, setActionInProgress } = props;
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(existingFilters);
  const loadingOrdersForAccountId = useSelector((state: IAppState) => state.productState.loadingOrdersForAccountId);
  const accountsOrders = useSelector((state: IAppState) => state.productState.accountsOrders);
  const filterChildrenOfAccountId = useSelector((state: IAppState) => state.accountState.filterChildrenOfAccountId);
  const directChildrenOnly = useSelector((state: IAppState) => state.accountState.directChildrenOnly);
  const hasSubpartners = useSelector((state: IAppState) => state.generalState.hasSubpartners);
  const loadingFilteredAccounts = useSelector((state: IAppState) => state.accountState.loadingFilteredAccounts);
  const itemsToDisplay = useSelector((state: IAppState) => state.accountState.itemsToDisplay);
  const [filterResults, setFilterResults] = useState<IAccount[]>([]);

  const navigate = useNavigate();

  let initialStatuses: IFilterCheckbox[] = [
    { label: FilterAccountsOptions.NoActivatedProducts, checked: false, disabled: false },
    { label: FilterAccountsOptions.HasActivationErrors, checked: false, disabled: false },
    { label: FilterAccountsOptions.M365LinkedOnly, checked: false, disabled: false },
    { label: FilterAccountsOptions.M365UnlinkedOnly, checked: false, disabled: false },
    { label: FilterAccountsOptions.HasSMBLogins, checked: false, disabled: false },
    { label: FilterAccountsOptions.LoginUserAssociated, checked: false, disabled: false },
    { label: FilterAccountsOptions.HasIntronisBackup, checked: false, disabled: false },
  ];

  const [userAssociatedWithEmail, setUserAssociatedWithEmail] = useState(filters?.associatedLogin);
  const [userAssociatedWithEmailError, setUserAssociatedWithEmailError] = useState("");

  const { statuses, activeProducts } = initializeFilterResults(existingFilters, initialStatuses, loadingOrdersForAccountId, accountsOrders, filterChildrenOfAccountId);
  const [newStatuses, setNewStatuses] = useState(statuses);
  const [newActiveProducts, setNewActiveProducts] = useState<IFilterCheckbox[]>(activeProducts);

  const updateFilterResults = (currentFilters: IAccountFilters, newProd: IFilterCheckbox[], newStats: IFilterCheckbox[]) => {
    if (loadingOrdersForAccountId === 0) {
      setNewStatuses(computeNewStatusesCheckboxesFromState(newStats, currentFilters));
      if (newProd.length > 0 && ((currentFilters?.seProdSkus && currentFilters.seProdSkus.length > 0) || (currentFilters?.essSkus && currentFilters.essSkus.length > 0) || currentFilters?.csProdSkus || currentFilters?.bbsProdSkus)) {
        setNewActiveProducts(computeNewActiveProductsCheckboxesFromState(newProd, currentFilters));
      }
    }
  };

  useEffect(() => {
    if (itemsToDisplay) {
      setFilterResults(itemsToDisplay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleConfirmFilter();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  const isFirstRender = React.useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      if (newFilterByName === existingFilters.name) return;
    }

    const newFilters = computeStateFiltersFromCheckboxes(newActiveProducts, newStatuses, newFilterByName, userAssociatedWithEmail);
    filterAccAction(newFilters).then(result => {
      if (result) {
        setFilterResults(result);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFilterByName]);

  const filterAccAction = useCallback(
    (newFilters: IAccountFilters) => {
      return new Promise<any>((resolve, reject) => {
        const result = dispatch(filterAccountsFromFiltersPopupAction(newFilters));
        resolve(result);
      });
    },
    [dispatch],
  );

  const handleActiveProductsFilter = useCallback(
    (productsFilterOptions: IFilterCheckbox[], index: number) => {
      let result = setActiveProductsFilter(productsFilterOptions, index, newStatuses);
      setNewActiveProducts(result.productsFilter);
      const newFilters = computeStateFiltersFromCheckboxes(result.productsFilter, newStatuses, newFilterByName, userAssociatedWithEmail);
      setFilters(newFilters);
      updateFilterResults(newFilters, result.productsFilter, newStatuses);
      filterAccAction(newFilters).then(result => {
        if (result) {
          setFilterResults(result);
        }
      });
    },
    [newStatuses, newFilterByName, userAssociatedWithEmail, filterAccAction],
  );

  const handleStatusesFilter = useCallback(
    (statusesFilterOptions: IFilterCheckbox[], index: number, userAssociatedWithEmailParam?: string) => {
      const currentFilterName = localStorage.getItem("currentFilterName") ?? "";
      setUserAssociatedWithEmail(userAssociatedWithEmailParam);
      let result = setStatusesFilter(statusesFilterOptions, index, newActiveProducts);
      setNewStatuses(result.statusesFilter);
      const newFilters = computeStateFiltersFromCheckboxes(newActiveProducts, result.statusesFilter, currentFilterName, userAssociatedWithEmailParam);
      setFilters(newFilters);
      updateFilterResults(newFilters, newActiveProducts, result.statusesFilter);
      filterAccAction(newFilters).then(result => {
        if (result) {
          setFilterResults(result);
        }
      });
    },
    [newActiveProducts, filterAccAction],
  );

  const handleConfirmFilter = useCallback(() => {
    let isError = false;
    let isAssociatedChecked = newStatuses.find(e => e.label === FilterAccountsOptions.LoginUserAssociated);
    if (isAssociatedChecked?.checked) {
      isError = validateUserAssociatedWithEmail(userAssociatedWithEmail!);
    }
    if (!isError) {
      const newFilters = computeStateFiltersFromCheckboxes(newActiveProducts, newStatuses, newFilterByName, userAssociatedWithEmail);
      const filterBuilder = new URLFilterBuilder(newFilters, filterChildrenOfAccountId, directChildrenOnly, hasSubpartners);
      let filtersToUrl = filterBuilder.BuildFilterUrl();

      if (areFiltersActive(newFilters)) {
        dispatch(applyfilterAccountsResultsAction(filterResults, newFilters));
        console.log("filtersToUrl: ", filtersToUrl);
        if (filtersToUrl.length > 1) {
          navigate("/filters?" + filtersToUrl.slice(1));
        } else {
          navigate("/");
        }
      } else {
        if (!window.location.pathname.includes("/accounts/")) {
          const urlParams = new URLSearchParams(window.location.search);
          const accountsOfValue = urlParams.get("accountsOf");

          if (accountsOfValue) {
            navigate(`/accounts/${accountsOfValue}/products-services`);
          } else {
            dispatch(resetFiltersAction());
            navigate("/");
          }
        }
      }
      onClose();
    }
  }, [newStatuses, userAssociatedWithEmail, newActiveProducts, newFilterByName, filterChildrenOfAccountId, directChildrenOnly, hasSubpartners, filterResults, dispatch, navigate, onClose]);

  const onCancelButtonPressed = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleOnLoginUserAssociatedWithThem = useCallback((value: string) => {
    setUserAssociatedWithEmailError("");
    setUserAssociatedWithEmail(value);
  }, []);

  const validateUserAssociatedWithEmail = (accName: string) => {
    let isErrorUserAssociatedWithEmail = false;
    if (!accName) {
      setUserAssociatedWithEmailError("Enter email");
      console.log("Enter email");

      isErrorUserAssociatedWithEmail = true;
    } else if (!EmailValidationRule.RegularExpression.test(accName)) {
      setUserAssociatedWithEmailError("Invalid email");
      console.log("Invalid email");
      isErrorUserAssociatedWithEmail = true;
    }
    return isErrorUserAssociatedWithEmail;
  };

  const handleOnItemSelected = useCallback(
    async (item: IAccount) => {
      onSearchClear();

      dispatch(goToAccountIdAction(item.id, false));
      const targetPath = `/accounts/${item.id}/products-services`;
      if (window.location.pathname !== targetPath) {
        navigate(targetPath);
      }
    },
    [dispatch, onSearchClear, navigate],
  );

  useWhyDidYouUpdate("FilterAccountPopover", props);

  return (
    <div data-testid="showFilterAccountsDialog">
      <div className="showFilterDialog" style={{ width: "1024px" }}>
        <div>
          <Grid container direction="row" data-testid="filteredAccountsTable">
            <Grid item xs={6} style={{ paddingBottom: "25px", paddingRight: "10px", borderRight: "1px solid #cbcbcb" }}>
              <div style={{ position: "relative" }}>
                <Backdrop className={"parentOpacity"} open={loadingFilteredAccounts} style={{ position: "absolute", zIndex: 1202 }}>
                  <CircularProgress data-testid="loadingFilteredAccounts" size="80px" style={{ zIndex: 1203 }} />
                </Backdrop>
                <AccountsTableBase showExpandIcon={false} pageSize={10} pageNumber={1} items={filterResults} selectedAccount={undefined} hasSubpartners={false} viewSearchResults={true} loadingAccountId={0} onItemSelected={handleOnItemSelected} onItemChecked={() => {}} onPageSizeChange={() => {}} onPageNumberChange={() => {}} showCheckboxes={false} checkboxesDisabled={false} isBaLoggedIn={false} showInfoIcon={false} defaultPagination={PAGE_SIZES} />
              </div>
            </Grid>

            <Grid item xs={6} style={{ paddingLeft: "20px", textAlign: "justify" }}>
              {newActiveProducts.length > 0 && (
                <>
                  <ActivatedProductsServicesTitle />
                  <div data-testid="filterAccountProductOptions">
                    <FilterAccountsCheckboxes options={newActiveProducts} onCheckboxOptionsChange={handleActiveProductsFilter} setInitialFocus={setInitialFocus}></FilterAccountsCheckboxes>
                  </div>
                </>
              )}
              <StatusesServicesTitle />
              <div data-testid="filterAccountStatusOptions">
                <FilterAccountsCheckboxes setInitialFocus={setInitialFocus} options={newStatuses} onCheckboxOptionsChange={handleStatusesFilter} onLoginUserAssociatedWithThem={handleOnLoginUserAssociatedWithThem} userAssociatedWithThem={userAssociatedWithEmail} userAssociatedWithThemError={userAssociatedWithEmailError} setActionInProgress={setActionInProgress}></FilterAccountsCheckboxes>
              </div>
            </Grid>
          </Grid>
        </div>

        <div key={"buttons"} style={{ display: "flex", justifyContent: "flex-end" }}>
          <CancelButton onCancelButtonPressed={onCancelButtonPressed} />
          <ApplyFiltersButton actionInProgress={actionInProgress} loadingFilteredAccounts={loadingFilteredAccounts} handleConfirmFilter={handleConfirmFilter} />
        </div>
      </div>
    </div>
  );
};

const ActivatedProductsServicesTitle = React.memo(() => {
  return (
    <Typography key="activatedProductsServicesCategoryTxt" data-testid="activatedProductsServicesCategoryTxt" variant="subtitle2" noWrap className="PrivilegesSectionTitle" style={{ paddingBottom: "10px" }}>
      Activated Products & Services
    </Typography>
  );
});

const StatusesServicesTitle = React.memo(() => {
  return (
    <Typography key={"statusesCategoryTxt"} data-testid="statusesCategoryTxt" variant="subtitle2" noWrap className="PrivilegesSectionTitle">
      Statuses
    </Typography>
  );
});

interface CancelButtonProps {
  onCancelButtonPressed: () => void;
}

const CancelButton: React.FC<CancelButtonProps> = React.memo(({ onCancelButtonPressed }) => {
  return (
    <Button data-testid="cancelFilterAccoutListDialog" variant="text" size="large" onClick={onCancelButtonPressed} style={{ marginRight: "15px" }}>
      CANCEL
    </Button>
  );
});

interface ApplyFiltersButtonProps {
  actionInProgress: boolean;
  loadingFilteredAccounts: boolean;
  handleConfirmFilter: () => void;
}

const ApplyFiltersButton: React.FC<ApplyFiltersButtonProps> = React.memo(({ actionInProgress, loadingFilteredAccounts, handleConfirmFilter }) => {
  return (
    <Button data-testid="confirmFilterAccoutListDialog" isLoading={actionInProgress || loadingFilteredAccounts} size="large" onClick={handleConfirmFilter} disabled={actionInProgress || loadingFilteredAccounts}>
      APPLY FILTERS
    </Button>
  );
});

export default React.memo(FilterAccountPopover);
