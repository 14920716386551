import FilterAccountsOptions from "../../../models/FilterAccountsOptions";
import IAccountOrders from "../../../models/Products/IAccountOrders";
import IOrderSlim from "../../../models/Products/IOrderSlim";
import ProductFamily from "../../../models/Products/ProductFamily";

export enum FilterAccountsUrlOptions {
  accountsOf = "accountsOf",
  accountName = "accountName",
  essSkus = "essSkus",
  seProdSkus = "seProdSkus",
  csProdSkus = "csProdSkus",
  bbsProdSkus = "bbsProdSkus",
  allProducts = "allProducts",
  noProducts = "noProducts",
  hasErrors = "hasErrors",
  onlyM365Linked = "onlyM365Linked",
  onlyM365Unlinked = "onlyM365Unlinked",
  hasSmbLogins = "hasSmbLogins",
  associatedLogin = "associatedLogin",
  hasIbu = "hasIbu",
}

export enum FilterAccountsUrlOptionsMap {
  essSkus = ProductFamily.ESSENTIALS_SERIVICES,
  csProdSkus = ProductFamily.CONTENT_SHIELD,
  bbsProdSkus = ProductFamily.BACKUP_APPLIANCES,
  allProducts = FilterAccountsOptions.AllActivatedProductsServices,
  noProducts = FilterAccountsOptions.NoActivatedProducts,
  hasErrors = FilterAccountsOptions.HasActivationErrors,
  onlyM365Linked = FilterAccountsOptions.M365LinkedOnly,
  onlyM365Unlinked = FilterAccountsOptions.M365UnlinkedOnly,
  hasSmbLogins = FilterAccountsOptions.HasSMBLogins,
  associatedLogin = FilterAccountsOptions.LoginUserAssociated,
  hasIbu = FilterAccountsOptions.HasIntronisBackup,
}

export class UrlFilterParams {
  private url: string;
  private params: URLSearchParams;
  private orders: IAccountOrders[];

  constructor(url: string, orders: IAccountOrders[]) {
    this.url = url;
    this.params = new URLSearchParams(this.url);
    this.orders = orders;
  }

  delete(param: string): string {
    const field = this.getOptionField(param, this.orders);
    let skuBundle = "";
    if (field === FilterAccountsUrlOptions.essSkus || field === FilterAccountsUrlOptions.seProdSkus) {
      const order = getOrderByBundleName(this.orders, param);

      if (order !== undefined) {
        const skuParams = this.params.get(field);
        skuBundle =
          skuParams
            ?.split(",")
            .filter(sku => sku !== order.bundleSku)
            .join(",") + "";
      }
      console.log(order);
    }

    this.params.delete(field);
    if (skuBundle !== "") {
      this.params.set(field, skuBundle);
    }
    this.url = this.params.toString();
    return this.url.toString();
  }

  searchSkuOptionInOrders(option: string, orders: IAccountOrders[], familyName: string): boolean {
    if (orders.length === 0) {
      return false;
    }

    let found: IOrderSlim | undefined;
    for (const order of orders) {
      found = order.orders.find(order => order.bundleName === option);
      if (found) {
        break;
      }
    }

    if (found?.familyName === familyName) {
      return true;
    }

    return false;
  }

  getOptionField(option: string, orders: IAccountOrders[]): string {
    switch (option) {
      case FilterAccountsUrlOptionsMap.csProdSkus.toString():
        return FilterAccountsUrlOptions.csProdSkus;
      case FilterAccountsUrlOptionsMap.bbsProdSkus.toString():
        return FilterAccountsUrlOptions.bbsProdSkus;
      case FilterAccountsUrlOptionsMap.allProducts.toString():
        return FilterAccountsUrlOptions.allProducts;
      case FilterAccountsUrlOptionsMap.noProducts.toString():
        return FilterAccountsUrlOptions.noProducts.toString();
      case FilterAccountsUrlOptionsMap.hasErrors.toString():
        return FilterAccountsUrlOptions.hasErrors;
      case FilterAccountsUrlOptionsMap.onlyM365Linked.toString():
        return FilterAccountsUrlOptions.onlyM365Linked;
      case FilterAccountsUrlOptionsMap.onlyM365Unlinked.toString():
        return FilterAccountsUrlOptions.onlyM365Unlinked;
      case FilterAccountsUrlOptionsMap.hasSmbLogins.toString():
        return FilterAccountsUrlOptions.hasSmbLogins;
      case FilterAccountsUrlOptionsMap.associatedLogin.toString():
        return FilterAccountsUrlOptions.associatedLogin;
      case FilterAccountsUrlOptionsMap.hasIbu.toString():
        return FilterAccountsUrlOptions.hasIbu;
      default:
        if (this.searchSkuOptionInOrders(option, orders, "Essentials Services")) {
          return FilterAccountsUrlOptions.essSkus;
        }
        if (this.searchSkuOptionInOrders(option, orders, "SecureEdge Services")) {
          return FilterAccountsUrlOptions.seProdSkus;
        }

        //todo could be accountName
        return "Unknown Option";
    }
  }
}
function getOrderByBundleName(orders: IAccountOrders[], param: string) {
  for (const order of orders) {
    const item = order?.orders.find(item => item.bundleName === param);
    if (item) {
      return item;
    }
  }
}

export const filtersAreActive = (): boolean => {
  if (window.location.search.length > 0) {
    return true;
  }

  return false;
};
